<template>
  <div class="footer-sticky-cta-spacing" />
</template>

<style lang="scss" scoped>
.footer-sticky-cta-spacing {
  height: rem(100px);
  background-color: var(--brand-500);
}
</style>
